import axios from 'axios'

const { origin, hostname } = window.location
const service = axios.create({
//baseUrl: process.env.NODE_ENV=="development"?'/': process.env.BASE_URL,
baseUrl:'https://api.ordercenter.link',
timeout: 5000,
headers: { "Content-Type": "application/json;charset=utf-8" }, //请求头
})
//在开发环境中的测试 development
// if(process.env.NODE_ENV == 'development') {
// axios.defaults.baseURL = 'http://120.53.31.103:84/'
// }
//在生产环境中的测试 production
// if(process.env.NODE_ENV == 'production') {
// axios.defaults.baseURL = 'https://wap.365msmk.com/'
// }

// POST请求
export function get(url, params) {
return new Promise((resolve, reject) => {
service.get(url, {
params: params
}).then(res => {
resolve(res)
}).catch(err => {
reject(err)
})
})
}
// get请求
export function post(url, params) {
return new Promise((resolve, reject) => {
service.post(url, params)
.then(res => {
resolve(res.data)
})
.catch(err => {
reject(err.data)
})
})
}

// 1.axios 拦截器
// 1.1请求拦截
service.interceptors.request.use(config => {
return config
}, err => {
console.log(err)
})

//1.2响应拦截

service.interceptors.response.use(
response => {
if (response.status === 200) {
return Promise.resolve(response);
} else {
return Promise.reject(response);
}
},
// 服务器状态码不是200的情况
error => {
if (error.response.status) {
switch (error.response.status) {
// 401: 未登录
// 未登录则跳转登录页面，并携带当前页面的路径
// 在登录成功后返回当前页面，这一步需要在登录页操作。
case 401:
notification.error({
message: "请求失败",
description: "登录过期，请重新登录"
});
axios.get('/relog').then(() => {
location.href = window.location.origin
})
break;
// 403 token过期
// 登录过期对用户进行提示
// 清除本地token和清空vuex中token对象
// 跳转登录页面
case 403:
notification.error({
message: "请求失败",
description: "登录过期，请重新登录"
});
break;
// 404请求不存在
case 404:
notification.error({
message: "请求失败",
description: "网络请求不存在"
});
break;
// 其他错误，直接抛出错误提示
default:
notification.error({
message: "请求失败",
description: error.response.data.message
});
}
return Promise.reject(error.response);
}
}
);
