<template>
	<div class="right-search">
		<div class="right-search-box" :style="{borderColor:isFocusInput}">
			<div class="flex-col">
				<div class="flex-text">
					<textarea class="textarea-text" :rows="isRows" :disabled="isDisabled" placeholder="最多输入50个运输单,每行一个"
						v-model="textarea" @blur="blurInput" @focus="focusInput" @input="textInput"
						@keypress="enterCode($event)"></textarea>
					<div class="flex-row-list" v-for="(item,index) in orderList" :key="index">
						<div class="flex-row-l">
							<span>{{index+1}}.</span>
							<span>{{item}}</span>
						</div>
						<div class="delete-icon" @click="delCode(index)" v-if="isNull">
							<i class="el-icon-error"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="felx-search">
				<div class="felx-search-handle" v-if="false">
					<i class="el-icon-truck"></i>
					<span>自动检测物流指南</span>
				</div>
				<p class="felx-remove" @click="removeAll">移除全部</p>
			</div>
		</div>
		<el-button class="search-btn" type="warning" @click="searchShip">查询</el-button>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isRows: null,
				isDisabled: false,
				isNull: false,
				isFocusInput: '',
				textarea: "",
				msg: '',
				orderList: ['']
			}
		},
		watch: {
			orderList(newVal, oldVal) {
				if (newVal.length === 1 && newVal[0] === '') {
					this.isNull = false
				} else {
					this.isNull = true
				}
			}
		},
		mounted() {

		},

		methods: {
			focusInput() {
				this.isFocusInput = '#F39C12'
			},
			blurInput() {
				this.isFocusInput = ''
			},
			textInput() {
				let msg = JSON.parse(JSON.stringify(this.textarea))
				let item = msg.split(/[(\r\n)\r\n]/); //textarea 的值 msg  通过换行或回车把多行数字分割成数组, 并且去掉数组中空的值: /[(\r\n)\r\n]+/
				this.orderList = item
			},
			enterCode(e) {
				let msg = JSON.parse(JSON.stringify(this.textarea))
				let item = msg.split(/[(\r\n)\r\n]/); //textarea 的值 msg  通过换行或回车把多行数字分割成数组, 并且去掉数组中空的值: /[(\r\n)\r\n]+/
				if (item.length >= 50) {
					if (e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) {
						e.returnValue = false;
						return false;
					}
				}
				this.orderList = item

			},
			delCode(index) {
				this.orderList.splice(index, 1)
				this.textarea = this.orderList.join('\n')

				if (this.orderList.length < 1) {
					this.orderList = ['']
				}
			},
			removeAll() {
				this.orderList = ['']
				this.textarea = ''
			},
			searchShip() {

				const params = this.orderList.toString()
				// if (params === '') {
				// 	this.$message({
				// 		type: 'info',
				// 		message: '请输入查询单号'
				// 	});
				// 	return
				// }
				this.$emit('searchShipData', params)

			}

		}
	}
</script>
<style scoped>
	.right-search {
		padding: 20px;
		margin-right: 40px;

		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	}

	.right-search-box {
		border-radius: 3px;
		border: 1px solid #333;
	}

	.flex-text {
		position: relative;
		min-height: 185px;
		flex: 1 1 0%;
	}

	.textarea-text {
		width: calc(100% - 70px);
		background: transparent;
		resize: none;
		height: 100%;
		overflow: hidden;
		margin-left: 32px;
		border: none;
		line-height: 28px;
		caret-color: #333;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
		color: transparent;
	}

	.textarea-text:focus {
		outline: none !important;
	}

	.flex-col {
		height: 226px;
		overflow-y: auto;
	}

	.flex-row {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.flex-row-list {
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
		line-height: 28px;
		height: 28px;
	}

	.flex-row-list:nth-child(odd) {
		background: #ffffff;
	}

	.flex-row-list:nth-child(even) {
		background: #F9FAFB;
	}

	.delete-icon {
		color: #BABEC3;
	}

	.flex-row-l>span:first-child {
		color: #7C828F;
		font-weight: 400;
		font-size: 16px;
	}

	.flex-row-l>span:last-child {
		word-break: break-all;

	}

	.felx-search {
		display: flex;
		color: #F39C12;
		justify-content: space-between;
		padding: 10px;
		cursor: pointer;
	}

	.search-btn {
		width: 100%;
		margin-top: 10px;
	}
</style>