import {get,post} from './index.js'

//封装接口的方法
// export function getTraceData() {
// return get('/v1/waybill/getTrace')
// }
export function getTraceData(num) {
return post('/v1/waybill/getTrace',{
waybillNo : num,
})
}

export function getTraceCountData(num) {
return post('/v1/waybill/getTraceCount',{
//这里用的是params传参，直接写{}就可，不用再声明params啦
waybillNo : num,
})
}
export function traceRecordData(vo,item) {
return post('/v1/waybill/traceRecord',{
traceVo:vo,
createWaybillTrace : item,
})
}