<template>
	<div class="ship-line">
		<div class="states-info" v-for="(item,index) in traceList" :key="index">
			<div class="info-time" @click="handleExpand(item)">
				<i class="el-icon-truck"></i>
				<div class="ship-order">
					<p class="order-number">
						<span id="text" class="order-number-t">{{item.waybillNo}}</span>
						<i class="el-icon-copy-document" @click="copyText"></i>
						<textarea id="input" class="copy-text"></textarea>
					</p>
					<p style="display: inline-block;">
						<span class="status-ship">{{item.status|statusFilter}}</span>
						<span class="status-day"> · {{item.duration}}天</span>
					</p>
				</div>
				<div class="company">
					<p class="company-name">{{item.logisticsName}}</p>
					<!-- <P class="company-info">Priority Mail</P> -->
				</div>
				<div class="date-info">
					<p>
						<span>{{item.lastTime}} </span>
						<span>{{item.lastMessage}}</span>
					</p>
				</div>
				<div :class="['expand-icon',{'expand-close':!item.isExpand}]">
					<i class="el-icon-arrow-down"></i>
				</div>
			</div>
			<div class="ship-line-box" v-if="item.isExpand">
				<div class="process-status">
					<p class="process-status-title">{{item.status|statusFilter}}</p>
					<p class="process-status-address" v-if="false">
						<span class="change-address">修改寄送地址</span>
						<span>联系物流公司</span>
					</p>
				</div>
				<div class="progress">
					<el-progress :percentage="item.percentage" :stroke-width="12" status="warning" :color="item.customColors"
						:show-text="false"></el-progress>
					<p class="progress-day">
						<span>已运输 {{item.duration}} 天</span>
					</p>
				</div>
				<div class="time-line-box">
					<el-timeline>
						<el-timeline-item v-for="(activity, index) in item.waybillTraceDetails" :key="index"
							:timestamp="activity.timestamp">
							{{activity.content}}
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			traceDetail: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				percentage: 50,
				// isExpand: true,
				traceList: [],
				orderList: [{
						isExpand: true,
						states: 'y',
					},
					{
						isExpand: true,
						states: 'y',
					}
				],
				activities: [{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					}, {
						content: '通过审核',
						timestamp: '2018-04-13'
					}, {
						content: '创建成功',
						timestamp: '2018-04-11'
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
					{
						content: 'Delivered, In/At Mailbox',
						timestamp: 'Sep 07 2021 12:28 pm· WATERTOWN, WI, 53094, USA, United States',
					},
				]
			}
		},
		watch: {
			traceDetail: {
				handler(newVal, oldVal) {
					this.traceList = newVal
					this.getShipData()
				},
				deep: true
			}
		},
		mounted() {
			// this.getShipData()
		},
		filters: {
			statusFilter(e) {
				let status = ''
				switch (e) {
					case '1':
						status = '收到消息'
						break;

					case '2':
						status = '正在运输中'
						break;

					case '3':
						status = '正在派送中'
						break;
					case '4':
						status = '待取件'
						break;
					case '5':
						status = '投递失败'
						break;
					case '6':
						status = '成功签收'
						break;
					case '7':
						status = '运输超时'
						break;
					case '8':
						status = '异常'
						break;
					default:
						status = '收到消息'
						break;
				}
				return status
			}
		},
		methods: {
			handleExpand(item) {
				item.isExpand = !item.isExpand
			},
			copyText() {
				var text = document.getElementById("text").innerText;
				var input = document.getElementById("input");
				input.value = text; // 修改文本框的内容
				input.select(); // 选中文本
				document.execCommand("copy"); // 执行浏览器复制命令
			},
			// 获取接口数据
			getShipData() {
				if (this.traceList.length > 0) {
					let data = this.traceList.map((item, index) => {
						// 字段拼接
						item.waybillTraceDetails.forEach(x => {
							x.content = x.opTime + ', ' + x.location + ', ' + x.country
							x.timestamp = this.$options.filters['statusFilter'](x.status) + ', ' + x.message
						})

						// 进度条颜色
						let status = '#214977'
						let percentage = 20
						switch (item.status) {
							case '1':
								status = '#214977'
								percentage = 20
								break;

							case '2':
								status = '#65AEE0'
								percentage = 40
								break;

							case '3':
								status = '#F5A551'
								percentage = 60
								break;
							case '4':
								status = '#EF7414'
								percentage = 80
								break;
							case '5':
								status = '#BC90CB'
								percentage = 70
								break;
							case '6':
								status = '#4CBB87'
								percentage = 100
								break;
							case '7':
								status = '#616E7D'
								percentage = 70
								break;
							case '8':
								status = '#D26759'
								percentage = 70
								break;
							default:
								status = '#214977'
								percentage = 20
								break;
						}
						item.customColors = status
						item.percentage = percentage
						return item


					})
					this.traceList = data
				} else {
					this.noData = true
				}
			}
		},

	}
</script>

<style scoped>
	.ship-line {
		padding: 0 44px;
		font-size: .875rem;
		margin-bottom: 40px;
	}

	.states-info {
		font-size: 15px;
		box-shadow: 0px 0px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
		border-radius: 3px;
		margin-bottom: 8px;
	}

	.info-time {
		display: flex;
		height: 40px;
		width: calc(100% - 32px);
		padding: 12px 16px;
		position: relative;
		cursor: pointer;
	}

	.info-time>.el-icon-truck {
		background: #65AEE0;
		border-radius: 50%;
		width: 38px;
		height: 38px;
		text-align: center;
		line-height: 38px;
		color: #fff;
		font-size: 22px;
		transform: rotateY(180deg);
		margin-right: 16px;
		flex-shrink: 0;
	}

	.order-number {
		font-size: 15px;
		font-weight: 700;

	}

	.order-number-t {
		width: calc(100% - 30px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
	}

	.order-number>.el-icon-copy-document {
		margin-left: 8px;
		vertical-align: super;
		color: #FFA300;
		font-weight: 700;
	}

	.status-dot {
		line-height: 20px;
		font-size: 15px;
		font-weight: 700;
	}

	.status-ship {
		color: rgb(100, 177, 228);
	}

	.ship-order {
		width: 25%;
		min-width: 200px;
	}

	.company {
		width: 20%;
		min-width: 180px;
		margin-left: 40px;

	}

	.company-name,
	.company-info {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.company-name {
		color: #2563EB;
		font-weight: 700;
	}

	.date-info,
	.expand-icon {
		display: flex;
		align-items: center;
	}

	.date-info {
		max-width: 300px;
		max-height: 37px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		padding-right: 35px;
	}

	.expand-icon {
		margin-top: 3px;
		position: absolute;
		right: 16px;
		top: 16px;
	}

	.expand-icon>i {
		font-weight: 700;
	}

	.expand-close i {
		transform: rotate(-90deg);
		transform-origin: (0, 0);
	}

	.ship-line-box {
		padding: 16px;
		border-top: 1px solid #E5E7EB;
	}

	.process-status {
		display: flex;
		justify-content: space-between
	}

	.process-status-title {
		font-size: 1.25rem;
		font-weight: 700;
	}

	.change-address {
		margin-right: 16px;
	}

	.progress {
		margin: 12px 0;
	}

	.progress-day {
		margin-top: 8px;
	}

	.time-line-box {
		margin: 25px 0;
	}

	.copy-text {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: -10;
	}
</style>
<style>
	.el-timeline-item__node {
		background: #ffffff;
		border: 1px solid #E4E7ED;
	}

	.el-timeline-item__tail {
		left: 5px;
		border-left: 1px solid #E4E7ED;
	}

	.el-timeline-item__content {
		font-weight: 600;
	}
</style>