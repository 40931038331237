<template>
	<div>
		<nav-bar></nav-bar>
		<div class="home-content">
			<ship-states class="home-left" :traceStatus="traceStatus" :traceDetail="traceDetail" @closeAllExpand="closeAllExpand"></ship-states>
			<div class="home-right"></div>
		</div>

		<div class="home-content">
			<div class="home-left">
				<div v-if="noData" class="no-data">暂无物流信息</div>
				<ship-line v-else :traceDetail="traceDetail"></ship-line>
			</div>
			<div class="home-right">
				<ship-search @searchShipData="searchShipData"></ship-search>
			</div>
		</div>
	</div>
</template>

<script>
	import navBar from '../components/navBar'
	import shipSearch from '../components/shipSearch'
	import shipStates from '../components/shipStates'
	import shipLine from '../components/shipLine'
	import {
		getTraceData,
		getTraceCountData,
	} from '../api/request'

	export default {
		name: 'home',
		components: {
			navBar,
			shipSearch,
			shipStates,
			shipLine
		},
		data() {
			return {
				traceStatus: [], //状态
				traceDetail: [], //物流详情
				noData: true,
			}
		},

		mounted() {
			var query = this.$route.query
			let params = query.n
			this.searchShipData(params)
			// this.getTrace()
			// this.getTraceCount()
		},

		methods: {
			// 点击查询
			searchShipData(params) {
				this.noData = false
				this.getTrace(params)
				this.getTraceCount(params)
			},
			// 获取物流信息
			async getTrace(params) {
				let res = await getTraceData(params)
				let data = []
				this.traceDetail = []

				if (res.length > 0) {
					data = res.map((item, index) => {
						item.isExpand = false
						if (index === 0) {
							item.isExpand = true
						}
						return item
					})
					this.traceDetail = data
				} else {
					this.noData = true
				}
			},
			// 获取状态数据
			async getTraceCount(params) {
				let res = await getTraceCountData(params)
				this.traceStatus = res

			},

			// 收起全部
			closeAllExpand(e) {
				if (this.traceDetail.length > 0) {
					this.traceDetail.forEach(item => {
						if (e) {
							item.isExpand = true
						} else {
							item.isExpand = false
						}

					})
				}
			}
		}

	}
</script>

<style scoped>
	.home-content {
		background: #ffffff;
		display: flex;
		width: 100%;
	}

	.home-right {
		width: 400px;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;
	}

	.home-left {
		flex: 1;
	}

	.no-data {
		color: #999999;
		margin: 0 44px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-size: .875rem;
		margin-bottom: 40px;
		box-shadow: 0px 0px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
		border-radius: 3px;
	}
</style>
