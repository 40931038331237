<template>
	<div class="home-status-bar">
		<div class="states-ul">
			<ul class="states-list">
				<li :class="['title-name',{'active-name':selected===-1}]" @click="selectedAll">全部({{total}})</li>
				<li v-for="(item,index) in statesList" :key="index" @click="selectedItem(item,index)">
					<el-tooltip placement="bottom" effect="light">
						<div slot="content">
							<p :class="[item.color,'icon-status']">{{item.tipsTitle}}</p>
							<p>{{item.tips}}</p>
						</div>
						<div :class="['title-name',{'active-name':selected===index}]">
							<i :class="[item.icon,'icon-style']" :style={background:item.bgColor}></i>
							<span :class="item.color">({{item.total}})</span>
						</div>
					</el-tooltip>
				</li>
			</ul>
			<div class="handle-icon">
				<el-tooltip effect="dark" :content="openBtn?'展开所有运单号的查询结果':'收起所有运单号的查询结果'" placement="top">
					<div :class="['close-btn',{'open-btn':openBtn}]" @click="sWitchHandle">
						<i class="el-icon-arrow-down"></i>
						<i class="el-icon-arrow-up"></i>
					</div>
				</el-tooltip>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		props: {
			traceStatus: {
				type: Array,
				default: () => []
			},
			traceDetail: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				total: 0, //全部
				selected: -1, //选中状态
				openBtn: false, // 展开收起物流信息
				traceStatusArr: [],
				statesList: [{
						color: 'color-received',
						icon: 'el-icon-document',
						tipsTitle: '已接收到包裹信息',
						tips: '物流商收到发货人的请求，即将取走包裹。',
						count: '1',
						bgColor: '#214977',
						status: "1",
						total: 0
					},
					{
						color: 'color-transport',
						icon: 'el-icon-truck',
						tipsTitle: '正在运输途中',
						tips: '物流商已接受或从发货人处取走包裹。货物已在途中。',
						count: '1',
						bgColor: '#65AEE0',
						status: "2",
						total: 0
					},
					{
						color: 'color-delivery',
						icon: 'el-icon-box',
						tipsTitle: '正在派送中',
						tips: '物流商即将交付包裹，或包裹准备送到取件地。',
						count: '1',
						bgColor: '#F5A551',
						status: "3",
						total: 0
					},
					{
						color: 'color-taken',
						icon: 'el-icon-money',
						tipsTitle: '待取件',
						tips: '物流商将包裹送到了指定的取件点。',
						count: '1',
						bgColor: '#EF7414',
						status: "4",
						total: 0
					},
					{
						color: 'color-fail',
						icon: 'el-icon-warning',
						tipsTitle: '投递失败',
						tips: '物流商尝试送货但是失败。通常物流商会通知你并且尝试再次送货。',
						count: '1',
						bgColor: '#BC90CB',
						status: "5",
						total: 0
					},
					{
						color: 'color-success',
						icon: 'el-icon-success',
						tipsTitle: '成功签收',
						tips: '包裹被成功签收。',
						count: '1',
						bgColor: '#4CBB87',
						status: "6",
						total: 0
					},
					// {
					// 	color: 'color-no-logistics',
					// 	icon: 'el-icon-help',
					// 	tipsTitle: '暂无物流信息',
					// 	tips: '物流商网站上没有包裹信息或能查询到的快递单号',
					// 	count: '1',
					// 	bgColor: '#CCCCCC',
					// 	status:"1",
					// 	total:0
					// },
					{
						color: 'color-longer',
						icon: 'el-icon-error',
						tipsTitle: '运输过久',
						tips: '在过去30天内，物流商没有提供包裹的物流信息。',
						count: '1',
						bgColor: '#616E7D',
						status: "7",
						total: 0
					},
					{
						color: 'color-abnormal',
						icon: 'el-icon-warning',
						tipsTitle: '可能出现异常',
						tips: '海关扣留、未交付、退回发件人的包裹，或任何其他运输例外情况。',
						count: '1',
						bgColor: '#D26759',
						status: "8",
						total: 0
					},
				]
			}
		},
		watch: {
			traceStatus: function (newVal, oldVal) {
				this.traceStatusArr = newVal
				this.getStatusTotal()
			}
		},
		mounted() {
			// setTimeout(()=>{
			// this.getStatusTotal()
			// },500)
		},
		methods: {
			selectedItem(item, index) {
				this.selected = index
			},
			selectedAll() {
				this.selected = -1
			},
			sWitchHandle() {
				this.openBtn = !this.openBtn
				this.$emit('closeAllExpand', !this.openBtn)
			},
			// 获取数据
			getStatusTotal() {
				let count = 0
				if (this.traceStatusArr.length > 0) {
					let data = this.statesList.map(item => {
						this.traceStatusArr.forEach(x => {
							if (item.status === x.status) {
								item.total = x.total
								this.total += x.total
							}
						})
						return item
					})
					this.statesList = data
				}
			}

		},

	}
</script>

<style scoped>
	.home-status-bar {
		margin-top: 65px;
	}

	.states-list {
		padding: 0 24px;
		display: flex;
	}

	.states-list li {
		height: 44px;
		margin-left: 20px;
		line-height: 44px;
	}

	.icon-status {
		font-weight: 700;
		line-height: 26px;
		font-size: 12px;

	}

	.icon-style {
		margin-right: 6px;
		background: #65AEE0;
		border-radius: 50%;
		padding: 4px;
		color: #fff;
		font-size: 10px;
	}

	/* 颜色 */
	.color-received {
		color: #214977;
	}

	.color-transport {
		color: #65AEE0;
	}

	.color-delivery {
		color: #F5A551;
	}

	.color-taken {
		color: #EF7414;
	}

	.color-fail {
		color: #BC90CB;
	}

	.color-success {
		color: #4CBB87;
	}

	.color-no-logistics {
		color: #CCCCCC;
	}

	.color-longer {
		color: #616E7D;
	}

	.color-abnormal {
		color: #D26759;
	}

	.active-name {
		border-bottom: 2px solid #FFA300
	}

	.title-name {
		white-space: nowrap;
	}

	.title-name:hover {
		cursor: pointer;
		border-bottom: 2px solid #dddddd;
	}

	.handle-icon {
		height: 44px;
		width: 100px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 44px;
	}

	.close-btn {
		width: 30px;
		height: 30px;
		border: 1px solid #cccccc;
		border-radius: 2px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		font-size: 14px;
		font-weight: bold;
	}

	.close-btn i:first-child {
		position: absolute;
		top: 4px;
	}

	.close-btn i:last-child {
		position: absolute;
		top: 13px;
	}

	.close-btn i {
		font-weight: 700;
	}

	.open-btn i {
		transform-origin: (0, 0);
		transform: rotate(180deg);
	}

	.states-ul {
		display: flex;
		justify-content: space-between;
	}
</style>