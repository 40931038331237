<template>
  <div class="nav">
    <div class="nav-box">
      <div class="nav-logo">
     <!-- <img src="../assets/logo.png" alt="logo" srcset=""> -->
	 <span style="color:#ffffff;font-size: 24px;font-weight: bold;vertical-align: center;line-height: 94px">全球货运物流查询平台</span>
    </div>
    <div class="nav-user-right">
      <span class="nav-title" style="width:60px;color:#ffffff;font-size: 20px;" @click="goHome">首页</span>
	  <span class="nav-title" style="width:60px;color:#ffffff;font-size: 20px;" @click="gologin">登录</span>
	  <span class="nav-title" style="width:60px;color:#ffffff;font-size: 20px;" @click="goregister">注册</span>
      <i class="el-icon-plus nav-add" v-if="false"></i>
    </div>
    </div>
  </div>
</template>

<script>
export default {
	methods:{
	 goHome(){
	       window.location.href="https://www.ordercenter.link/home";//当前标签页
	     },
	gologin(){
		       window.location.href="https://www.ordercenter.link/login";//当前标签页
		   },
	    goregister(){
	          window.location.href="https://www.ordercenter.link/register";//当前标签页
	        }
	}

}

</script>

<style scoped>
.nav{
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #D1D5DB;
  position: fixed;
  left: 0;
  top: 0;
  background:#1278b2;
  z-index: 999;
}
.nav-box{
  width:calc(100% - 80px);
  height: 65px;
  display: flex;
  align-items: center;
  background:#1278b2;
  justify-content: space-between;
  margin: 0 40px;
}
.nav-user-right{
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nav-title{
  font-family: Arial, Helvetica, sans-serif;
}
.nav-add{
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
}


</style>
